import React from "react";
import MUIDataTable from "mui-datatables";
import StudentDetailsDialog from "./StudentDetailsDialog";
import DialogDetails from "./DialogDetails";

const data = [];

class StudentTable extends React.Component {
  state = {
    data: [],
    open: false,
    gender: "",
    fname: "",
    city: "",
    columns: [
      {
        name: "no"
      },
      {
        name: "title"
      },
      {
        name: "title"
      },
      {
        name: "title"
      },
      {
        name: "title"
      },
      {
        name: "first"
      },
      {
        name: "city"
      }
    ]
  };

  componentDidMount() {
    fetch("https://randomuser.me/api/?results=10&nat=us")
      .then(Response => Response.json())
      .then(findresponse => {
        //console.log(findresponse.results.length);
        var data = [];
        if (findresponse.results.length > 0) {
          data = findresponse.results.map((value, index) => {
            return [
              ...data,
              value.gender,
              value.name.first,
              value.location.city
            ];
          });
        }

        console.log(data);

        this.setState({
          data: data
        });
        console.log(this.state.data);
      });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    alert("submit the form..............");
    console.log(
      "gender:" +
        this.state.gender +
        "name:" +
        this.state.fname +
        "city:" +
        this.state.city
    );
    const user = [
      {
        gender: this.state.gender,
        name: this.state.fname,
        city: this.state.city
      }
    ];
    console.log(user);
    var mydata = JSON.stringify(user);
    console.log("before pushing" + mydata);
    data.push(mydata);
    console.log("After pushing" + data);
    console.log("data length" + data.length);
    this.setState({
      newdata: data
    });
  };

  openDialog = rowIndex => {
    console.log("Inside OpenDialog");
    this.handleClickOpen();
    this.state.data.forEach((row, index) => {
      console.log(row);
      if (rowIndex === index) {
        this.setState({
          gender: row[0],
          fname: row[1],
          city: row[2]
        });
      }
    });
  };

  render() {
    const options = {
      filterType: "dropdown",
      responsive: "scroll",
      sort: "true",
      rowsPerPage: 5,
      onRowClick: (rowData, rowMeta) => {
        console.log(rowMeta);
        this.openDialog(rowMeta.rowIndex);
      }
    };


    const columns = ["名前", "数学", "英語", "社会"];
 
const data = [
 ["山田", "80", "76", "2"],
 ["鈴木", "80", "7", "80"],
 ["田中", "80", "7", "55"],
 ["岡田", "80", "7", "66"],
];
 

    return (
      <React.Fragment>
        <div>
            <MUIDataTable
            title={"成績"}
            data={data}
            columns={columns}
            options={options}
            />
        </div>
            <MUIDataTable
          title={"Employee list"}
          columns={this.state.columns}
          data={this.state.data}
          options={options}
        />
        <StudentDetailsDialog
          open={this.state.open}
          onClose={this.handleClose}
          gender={this.state.gender}
          fname={this.state.fname}
          city={this.state.city}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          newdata={this.state.newdata}
        />
      </React.Fragment>
    );
  }
}
export default StudentTable;
